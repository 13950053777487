import type { OptionalBool } from '@/common/models';

type IFeatureFlag<TValue extends boolean | string = boolean> = {
  envKey: keyof ImportMetaEnv;
  /** Default value absent the environment variable or local development override */
  valueDefault: TValue;
  /** Default value used in local development - typically doesn't match `valueDefault` */
  valueLocal: TValue;
};

class FeatureFlag<TValue extends boolean | string = boolean> implements IFeatureFlag<TValue> {
  readonly envKey: keyof ImportMetaEnv;

  constructor(
    readonly name: string,
    readonly valueDefault: TValue,
    readonly valueLocal: TValue
  ) {
    this.envKey = `ELEVATE_FF_${name}`;
  }

  get value(): TValue {
    if (import.meta.env.MODE === 'development') return this.valueLocal;
    return typeof this.valueDefault === 'string'
      ? (import.meta.env[this.envKey] ?? this.valueDefault)
      : (import.meta.env[this.envKey] ?? String(this.valueDefault)) === 'true';
  }
}

export class DynamicFeatureFlag extends FeatureFlag {
  private _preferredValue: OptionalBool;
  constructor(staticFlag: FeatureFlag, preferredValue?: OptionalBool) {
    super(staticFlag.name, staticFlag.valueDefault, staticFlag.valueLocal);
    this._preferredValue = preferredValue;
  }

  get preferredValue(): OptionalBool {
    return this._preferredValue;
  }

  set preferredValue(value: OptionalBool) {
    this._preferredValue = value;
  }

  get value(): boolean {
    if (typeof this._preferredValue === 'boolean') return this._preferredValue;
    return super.value;
  }
}

/**
 * Controls enablement of the Candid Chat UI components (preferences, etc.)
 *
 * - Environment variable: `ELEVATE_FF_PROMOHUB_ENABLED`
 * - Environment default: `false` (disabled)
 * - Local development default: `true` (enabled)
 */
const PROMOHUB_ENABLED = new FeatureFlag('PROMOHUB_ENABLED', false, true);

/**
 * Controls enablement of the Question Bank UI components (preferences, etc.)
 *
 * - Environment variable: `ELEVATE_FF_QUESTION_BANK_ENABLED`
 * - Environment default: `false` (disabled)
 * - Local development default: `true` (enabled)
 */
const QUESTION_BANK_ENABLED = new FeatureFlag('QUESTION_BANK_ENABLED', false, true);

/**
 * If enabled, show the new calendar components at the bottom of the `FindAvailableInterviewer` page.
 *
 * - Environment variable: `ELEVATE_FF_USE_EXPERIMENTAL_CALENDARING`
 * - Environment default: `false` (disabled)
 * - Local development default: `true` (enabled)
 */
const USE_EXPERIMENTAL_CALENDARING = new FeatureFlag('USE_EXPERIMENTAL_CALENDARING', false, true);

/**
 * Whether this environment is using the `classic` `OrgRoleLDAPGroups` flat model
 * or the new `platform` `RolePermissionGroup` model.
 *
 * - Environment variable: `ELEVATE_FF_PERMISSION_GROUP_MODEL`
 * - Environment default: `classic`
 * - Local development default: `classic`
 */
const PERMISSION_GROUP_MODEL = new FeatureFlag<PermissionModel>('PERMISSION_GROUP_MODEL', 'classic', 'classic');

/**
 * Whether CloudWatch RUM agent should be enabled.
 **
 * - Environment variable: `ELEVATE_FF_RUM_ENABLED`
 * - Environment default: `false` (disabled)
 * - Local development default: `false` (disabled)
 */
const RUM_ENABLED = new FeatureFlag('RUM_ENABLED', false, false);

/**
 * Whether x-ray tracing should be enabled in CloudWatch RUM. This has no effect if RUM is disabled.
 *
 * - Environment variable: `ELEVATE_FF_XRAY_ENABLED`
 * - Environment default: `false` (disabled)
 * - Local development default: `false` (disabled)
 */
const XRAY_ENABLED = new FeatureFlag('XRAY_ENABLED', false, false);

/**
 * Whether to enable export-to-Quip for loop events
 *
 * - Environment variable: `ELEVATE_FF_ENABLE_LOOP_QUIP_EXPORT`
 * - Environment default: `false` (disabled)
 * - Local development default: `true` (enabled)
 */
const ENABLE_LOOP_QUIP_EXPORT = new FeatureFlag('ENABLE_LOOP_QUIP_EXPORT', false, true);

/**
 * Whether to enable export-to-Quip for phone-screen events
 *
 * - Environment variable: `ELEVATE_FF_ENABLE_PHONE_SCREEN_QUIP_EXPORT`
 * - Environment default: `false` (disabled)
 * - Local development default: `true` (enabled)
 */
const ENABLE_PHONE_SCREEN_QUIP_EXPORT = new FeatureFlag('ENABLE_PHONE_SCREEN_QUIP_EXPORT', false, true);

export default {
  PROMOHUB_ENABLED,
  QUESTION_BANK_ENABLED,
  PERMISSION_GROUP_MODEL,
  USE_EXPERIMENTAL_CALENDARING,
  RUM_ENABLED,
  XRAY_ENABLED,
  ENABLE_LOOP_QUIP_EXPORT,
  ENABLE_PHONE_SCREEN_QUIP_EXPORT,
};
